<template>
  <div :class="['ele-exception ele-text-center',{'ele-exception-dark':$store.state.theme.theme==='dark'}]">
    <div class="ele-exception-img">
      <svg width="423" height="341" viewBox="0 0 423 341" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <g stroke="#A3B1BF" stroke-width="1.62" opacity=".6" stroke-linejoin="round" stroke-dasharray="12.15">
            <path
                d="M224.993 290.054c-65.929 0-119.375-53.434-119.375-119.35 0-65.915 53.446-119.35 119.375-119.35 65.928 0 119.374 53.435 119.374 119.35 0 65.916-53.446 119.35-119.374 119.35z"/>
            <path
                d="M224.823 339.72c-93.378 0-169.076-75.684-169.076-169.043 0-93.36 75.698-169.042 169.076-169.042s169.076 75.683 169.076 169.042c0 93.36-75.698 169.042-169.076 169.042z"/>
          </g>
          <path fill="#F5F5F5" d="M78.617 196.401l20.278-1.052L84.052 209.2z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M78.617 196.401l20.278-1.052L84.052 209.2z"/>
          <path d="M22.038 208.972l20.251-8.594 13.665 32.184-20.251 8.594z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M22.038 208.972l20.251-8.594 13.665 32.184-20.251 8.594z"/>
          <path fill="#F5F5F5" d="M38.867 193.414l4.997-2.12 19.721 46.447-4.997 2.12z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" fill="#F0F2F5" stroke-linejoin="round"
                d="M38.867 193.414l4.997-2.12 19.721 46.447-4.997 2.12z"/>
          <path d="M43.485 251.004l18.752-7.958 23.674 55.757-18.751 7.957z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M43.485 251.004l18.752-7.958 23.674 55.757-18.751 7.957z"/>
          <path d="M48.068 201.196l3.463-1.47 11.313 26.643-3.463 1.47z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M48.068 201.196l3.463-1.47 11.313 26.643-3.463 1.47z"/>
          <path fill="#F5F5F5" d="M53.783 204.42l8.022-3.405.765 1.802-8.022 3.404z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M53.783 204.42l8.022-3.405.765 1.802-8.022 3.404z"/>
          <path fill="#F5F5F5" d="M60.283 219.73l8.022-3.404.765 1.802-8.021 3.404z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M60.283 219.73l8.022-3.404.765 1.802-8.021 3.404z"/>
          <path d="M49.437 239.012l3.405 8.02-1.802.766-3.405-8.022z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M49.437 239.012l3.405 8.02-1.802.766-3.405-8.022z"/>
          <path d="M17.433 217.134l4.605-8.162 13.665 32.184-9.072-2.359z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M17.433 217.134l4.605-8.162 13.665 32.184-9.072-2.359z"/>
          <path d="M17.433 217.134l-5.653-3.81 13.666 32.185 1.185-6.712z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M17.433 217.134l-5.653-3.81 13.666 32.185 1.185-6.712z"/>
          <path d="M14.117 219.19l-5.772-1.165 4.074 9.596 3.171-4.96z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M14.117 219.19l-5.772-1.165 4.074 9.596 3.171-4.96z"/>
          <path d="M21.375 236.284l-5.772-1.165 4.074 9.595 3.171-4.96z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M21.375 236.284l-5.772-1.165 4.074 9.595 3.171-4.96z"/>
          <path fill="#F0F2F5" d="M52.498 201.393l2.41-1.023 3.3 7.77-2.41 1.024z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M52.498 201.393l2.41-1.023 3.3 7.77-2.41 1.024z"/>
          <path fill="#F0F2F5" d="M59.016 216.746l2.411-1.023 3.3 7.77-2.411 1.024z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M59.016 216.746l2.411-1.023 3.3 7.77-2.411 1.024z"/>
          <path d="M45.41 237.037l3.6-1.528 1.65 3.885-3.6 1.528z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" fill="#F5F5F5" stroke-linejoin="round"
                d="M45.41 237.037l3.6-1.528 1.65 3.885-3.6 1.528z"/>
          <path
              d="M66.817 209.043c-6.095-14.354-5.144-28.946 1.659-35.517 1.125-1.086 3.702-2.908 5.748 1.91l21.773 51.281c1.864 4.389-.907 5.75-2.468 5.805-9.455.33-20.617-9.123-26.712-23.479z"
              fill="#F5F5F5"/>
          <path
              d="M66.817 209.043c-6.095-14.354-5.144-28.946 1.659-35.517 1.125-1.086 3.702-2.908 5.748 1.91l21.773 51.281c1.864 4.389-.907 5.75-2.468 5.805-9.455.33-20.617-9.123-26.712-23.479z"
              stroke="#A3B1BF" stroke-width="1.62" fill="#F0F2F5" stroke-linejoin="round"/>
          <path fill="#F0F2F5" d="M61.153 199.48l2.41-1.022 8.552 20.139-2.411 1.023z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M61.153 199.48l2.41-1.022 8.552 20.139-2.411 1.023z"/>
          <path d="M100.73 197.68a2.863 2.863 0 1 1-2.237-5.272 2.863 2.863 0 0 1 2.237 5.272" fill="#A3B1BF"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M60.645 250.555l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M60.645 250.555l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M62.097 253.974l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M62.097 253.974l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M63.548 257.392l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M63.548 257.392l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M65 260.811l-.893-2.104 2.104-.892.893 2.103z"/>
          <path d="M65 260.811l-.893-2.104 2.104-.892.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M57.49 251.894l-.894-2.104 2.104-.893.893 2.104z"/>
          <path d="M57.49 251.894l-.894-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M58.94 255.313l-.893-2.104 2.104-.893.894 2.104z"/>
          <path d="M58.94 255.313l-.893-2.104 2.104-.893.894 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M60.392 258.732l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M59.5 256.628l2.103-.893.893 2.104-2.104.893z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M61.844 262.15l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M61.844 262.15l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M54.333 253.233l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M54.333 253.233l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M55.785 256.652l-.894-2.104 2.104-.892.894 2.103z"/>
          <path d="M55.785 256.652l-.894-2.104 2.104-.892.894 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M57.236 260.07l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M57.236 260.07l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M58.688 263.49l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M58.688 263.49l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M51.177 254.573l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M51.177 254.573l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M52.629 257.991l-.894-2.103 2.104-.893.893 2.104z"/>
          <path d="M52.629 257.991l-.894-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M54.08 261.41l-.893-2.103 2.104-.893.893 2.103z"/>
          <path d="M54.08 261.41l-.893-2.103 2.104-.893.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M55.532 264.83l-.893-2.105 2.103-.892.894 2.103z"/>
          <path d="M55.532 264.83l-.893-2.105 2.103-.892.894 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M48.02 255.912l-.892-2.104 2.104-.893.893 2.104z"/>
          <path d="M48.02 255.912l-.892-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M49.472 259.33l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M49.472 259.33l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M50.924 262.75l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M50.924 262.75l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M52.376 266.168l-.894-2.103 2.104-.893.894 2.104z"/>
          <path d="M52.376 266.168l-.894-2.103 2.104-.893.894 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M68.248 268.46l-.894-2.104 2.104-.893.893 2.104z"/>
          <path d="M68.248 268.46l-.894-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M69.7 271.878l-.894-2.103 2.104-.893.893 2.104z"/>
          <path d="M69.7 271.878l-.894-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M71.15 275.297l-.892-2.103 2.103-.893.894 2.103z"/>
          <path d="M71.15 275.297l-.892-2.103 2.103-.893.894 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M72.602 278.716l-.893-2.104 2.104-.892.893 2.103z"/>
          <path d="M72.602 278.716l-.893-2.104 2.104-.892.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M65.091 269.799l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M65.091 269.799l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M66.543 273.218l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M66.543 273.218l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M67.995 276.637l-.894-2.104 2.104-.893.894 2.104z"/>
          <path d="M67.995 276.637l-.894-2.104 2.104-.893.894 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M69.446 280.055l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M69.446 280.055l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M61.935 271.138l-.893-2.103 2.104-.893.893 2.103z"/>
          <path d="M61.935 271.138l-.893-2.103 2.104-.893.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M63.387 274.557l-.893-2.104 2.104-.892.893 2.103z"/>
          <path d="M63.387 274.557l-.893-2.104 2.104-.892.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M64.839 277.976l-.894-2.104 2.104-.893.893 2.104z"/>
          <path d="M64.839 277.976l-.894-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M66.29 281.395l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M66.29 281.395l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M58.78 272.478l-.894-2.104 2.104-.893.893 2.104z"/>
          <path d="M58.78 272.478l-.894-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M60.23 275.896l-.892-2.103 2.104-.893.893 2.104z"/>
          <path d="M60.23 275.896l-.892-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M61.682 279.315l-.893-2.104 2.104-.892.893 2.103z"/>
          <path d="M61.682 279.315l-.893-2.104 2.104-.892.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M63.134 282.734l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M63.134 282.734l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M55.623 273.817l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M55.623 273.817l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M57.075 277.236l-.893-2.104 2.103-.893.894 2.104z"/>
          <path d="M57.075 277.236l-.893-2.104 2.103-.893.894 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M58.526 280.654l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M58.526 280.654l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M59.978 284.073l-.893-2.103 2.104-.893.893 2.103z"/>
          <path d="M59.978 284.073l-.893-2.103 2.104-.893.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M75.85 286.365l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M75.85 286.365l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M77.302 289.783l-.894-2.103 2.104-.893.893 2.104z"/>
          <path d="M77.302 289.783l-.894-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M78.753 293.202l-.893-2.104 2.104-.892.893 2.103z"/>
          <path d="M78.753 293.202l-.893-2.104 2.104-.892.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M80.205 296.621l-.893-2.104 2.103-.893.894 2.104z"/>
          <path d="M80.205 296.621l-.893-2.104 2.103-.893.894 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M72.694 287.704L71.8 285.6l2.103-.893.894 2.104z"/>
          <path d="M72.694 287.704L71.8 285.6l2.103-.893.894 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M74.145 291.123l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M74.145 291.123l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M75.597 294.541l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M75.597 294.541l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M77.049 297.96l-.894-2.103 2.104-.893.894 2.103z"/>
          <path d="M77.049 297.96l-.894-2.103 2.104-.893.894 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M69.538 289.043l-.894-2.104 2.104-.892.894 2.103z"/>
          <path d="M69.538 289.043l-.894-2.104 2.104-.892.894 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M70.99 292.462l-.894-2.104 2.104-.893.893 2.104z"/>
          <path d="M70.99 292.462l-.894-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M72.44 295.88l-.892-2.103 2.104-.893.893 2.104z"/>
          <path d="M72.44 295.88l-.892-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M73.893 299.3l-.894-2.104 2.104-.893.893 2.104z"/>
          <path d="M73.893 299.3l-.894-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M66.382 290.382l-.894-2.103 2.104-.893.893 2.104z"/>
          <path d="M66.382 290.382l-.894-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M67.833 293.801l-.893-2.103 2.104-.893.893 2.103z"/>
          <path d="M67.833 293.801l-.893-2.103 2.104-.893.893 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M69.285 297.22l-.893-2.104 2.103-.892.894 2.103z"/>
          <path d="M69.285 297.22l-.893-2.104 2.103-.892.894 2.103z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M70.736 300.639l-.893-2.104 2.104-.893.893 2.104z"/>
          <path d="M70.736 300.639l-.893-2.104 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M63.226 291.722l-.894-2.104 2.104-.893.893 2.104z"/>
          <path d="M62.332 289.618l2.104-.893.893 2.104-2.103.893z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M64.677 295.14l-.893-2.103 2.104-.893.893 2.104z"/>
          <path d="M64.677 295.14l-.893-2.103 2.104-.893.893 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M66.129 298.56l-.893-2.104 2.103-.893.894 2.104z"/>
          <path d="M66.129 298.56l-.893-2.104 2.103-.893.894 2.104z"/>
          <path fill-opacity=".6" fill="#A3B1BF" d="M67.58 301.978l-.893-2.104 2.104-.892.893 2.103z"/>
          <path
              d="M67.58 301.978l-.893-2.104 2.104-.892.893 2.103zM20.241 196.26l18.752-7.957-23.674-55.758-18.752 7.958z"/>
          <path d="M33.004 200.31l-3.406-8.02-1.802.764 3.406 8.021z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"
                d="M33.004 200.31l-3.406-8.02-1.802.764 3.406 8.021z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M31.628 204.578l3.6-1.528-1.649-3.885-3.6 1.528z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" fill="#FFF" stroke-linejoin="round"
                d="M31.628 204.578l3.6-1.528-1.649-3.885-3.6 1.528z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M32.485 184.231l.893 2.104 2.104-.893-.893-2.103z"/>
          <path d="M32.485 184.231l.893 2.104 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M31.033 180.813l.893 2.103 2.104-.893-.893-2.103z"/>
          <path d="M31.033 180.813l.893 2.103 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M29.581 177.394l.894 2.103 2.104-.892-.894-2.104z"/>
          <path d="M29.581 177.394l.894 2.103 2.104-.892-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M28.13 173.975l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M28.13 173.975l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M29.329 185.57l.893 2.104 2.104-.892-.893-2.104z"/>
          <path d="M29.329 185.57l.893 2.104 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M27.877 182.152l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M27.877 182.152l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M26.425 178.733l.894 2.104 2.104-.893-.894-2.104z"/>
          <path d="M26.425 178.733l.894 2.104 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M24.974 175.314l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M24.974 175.314l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M26.173 186.91l.893 2.104 2.104-.893-.894-2.104z"/>
          <path d="M26.173 186.91l.893 2.104 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M24.72 183.491l.894 2.104 2.104-.893-.893-2.104z"/>
          <path d="M24.72 183.491l.894 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M23.27 180.072l.893 2.104 2.103-.893-.893-2.103z"/>
          <path d="M23.27 180.072l.893 2.104 2.103-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M21.818 176.654l.893 2.103 2.104-.893-.893-2.103z"/>
          <path d="M21.818 176.654l.893 2.103 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M23.016 188.25l.894 2.103 2.104-.893-.894-2.104z"/>
          <path d="M23.016 188.25l.894 2.103 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M21.565 184.83l.893 2.104 2.104-.893-.893-2.103z"/>
          <path d="M21.565 184.83l.893 2.104 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M20.113 181.412l.893 2.103 2.104-.892-.893-2.104z"/>
          <path d="M20.113 181.412l.893 2.103 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M18.662 177.993l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M18.662 177.993l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M19.86 189.589l.894 2.103 2.103-.893-.893-2.103z"/>
          <path d="M19.86 189.589l.894 2.103 2.103-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M18.409 186.17l.893 2.103 2.104-.892-.893-2.104z"/>
          <path d="M18.409 186.17l.893 2.103 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M16.957 182.75l.893 2.105 2.104-.893-.893-2.104z"/>
          <path d="M16.957 182.75l.893 2.105 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M15.505 179.332l.894 2.104 2.104-.893-.894-2.104z"/>
          <path d="M15.505 179.332l.894 2.104 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M24.882 166.326l.894 2.104 2.103-.893-.893-2.103z"/>
          <path d="M24.882 166.326l.894 2.104 2.103-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M23.43 162.908l.894 2.103 2.104-.892-.893-2.104z"/>
          <path d="M23.43 162.908l.894 2.103 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M21.98 159.489l.892 2.104 2.104-.893-.893-2.104z"/>
          <path d="M21.98 159.489l.892 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M20.528 156.07l.893 2.104 2.104-.893-.894-2.104z"/>
          <path d="M20.528 156.07l.893 2.104 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M21.726 167.666l.893 2.103 2.104-.892-.893-2.104z"/>
          <path d="M21.726 167.666l.893 2.103 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M20.275 164.247l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M20.275 164.247l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M18.823 160.828l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M18.823 160.828l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M17.371 157.41l.894 2.103 2.104-.893-.894-2.103z"/>
          <path d="M17.371 157.41l.894 2.103 2.104-.893-.894-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M18.57 169.005l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M18.57 169.005l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M17.119 165.586l.893 2.104 2.104-.893-.894-2.104z"/>
          <path d="M17.119 165.586l.893 2.104 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M15.667 162.167l.893 2.104 2.104-.893-.893-2.103z"/>
          <path d="M15.667 162.167l.893 2.104 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M14.215 158.749l.894 2.103 2.103-.892-.893-2.104z"/>
          <path d="M14.215 158.749l.894 2.103 2.103-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M15.414 170.344l.893 2.104 2.104-.893-.893-2.103z"/>
          <path d="M15.414 170.344l.893 2.104 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M13.963 166.926l.893 2.103 2.104-.893-.894-2.103z"/>
          <path d="M13.963 166.926l.893 2.103 2.104-.893-.894-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M12.51 163.507l.894 2.103 2.104-.892-.893-2.104z"/>
          <path d="M12.51 163.507l.894 2.103 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M11.06 160.088l.892 2.104 2.104-.893-.893-2.104z"/>
          <path d="M11.06 160.088l.892 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M12.258 171.684l.893 2.103 2.104-.892-.893-2.104z"/>
          <path d="M12.258 171.684l.893 2.103 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M10.806 168.265l.894 2.104 2.104-.893-.894-2.104z"/>
          <path d="M10.806 168.265l.894 2.104 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M9.355 164.846l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M9.355 164.846l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M7.903 161.427l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M7.903 161.427l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M17.28 148.422l.893 2.103 2.104-.893-.893-2.103z"/>
          <path d="M17.28 148.422l.893 2.103 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M15.828 145.003l.894 2.103 2.104-.892-.894-2.104z"/>
          <path d="M15.828 145.003l.894 2.103 2.104-.892-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M14.377 141.584l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M14.377 141.584l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M12.925 138.165l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill="#B2BECA" d="M13.818 140.269l2.104-.893-.893-2.104-2.104.893z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M14.124 149.76l.893 2.105 2.104-.893-.893-2.104z"/>
          <path d="M14.124 149.76l.893 2.105 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M12.672 146.342l.894 2.104 2.103-.893-.893-2.104z"/>
          <path d="M12.672 146.342l.894 2.104 2.103-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M11.22 142.923l.894 2.104 2.104-.893-.893-2.104z"/>
          <path d="M11.22 142.923l.894 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M9.77 139.504l.892 2.104 2.104-.893-.893-2.103z"/>
          <path d="M9.77 139.504l.892 2.104 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M10.968 151.1l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M10.968 151.1l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M9.516 147.681l.893 2.104 2.104-.893-.893-2.103z"/>
          <path d="M9.516 147.681l.893 2.104 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M8.065 144.262l.893 2.104 2.104-.893-.893-2.103z"/>
          <path d="M8.065 144.262l.893 2.104 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M6.613 140.844l.893 2.103 2.104-.892-.893-2.104z"/>
          <path d="M6.613 140.844l.893 2.103 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M7.812 152.44l.893 2.103 2.104-.893-.893-2.103z"/>
          <path d="M7.812 152.44l.893 2.103 2.104-.893-.893-2.103z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M6.36 149.02l.893 2.104 2.104-.892-.893-2.104z"/>
          <path d="M6.36 149.02l.893 2.104 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M4.909 145.602l.893 2.104 2.104-.893-.894-2.104z"/>
          <path d="M4.909 145.602l.893 2.104 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M3.457 142.183l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M3.457 142.183l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M4.656 153.779l.893 2.103 2.104-.892-.893-2.104z"/>
          <path d="M4.656 153.779l.893 2.103 2.104-.892-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M3.204 150.36l.893 2.104 2.104-.893-.893-2.104z"/>
          <path d="M3.204 150.36l.893 2.104 2.104-.893-.893-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M1.752 146.941l.894 2.104 2.104-.893-.894-2.104z"/>
          <path d="M1.752 146.941l.894 2.104 2.104-.893-.894-2.104z"/>
          <path fill-opacity=".8" fill="#A3B1BF" d="M.3 143.522l.894 2.104 2.104-.893-.893-2.104z"/>
          <path d="M.3 143.522l.894 2.104 2.104-.893-.893-2.104z"/>
          <path
              d="M108.976 190.44c-1.626-3.83-2.29-7.213-1.482-7.556.808-.343 2.781 2.484 4.408 6.314 1.625 3.83 2.29 7.213 1.481 7.555-.808.343-2.78-2.484-4.407-6.313z"
              fill="#F5F5F5"/>
          <path
              d="M108.976 190.44c-1.626-3.83-2.29-7.213-1.482-7.556.808-.343 2.781 2.484 4.408 6.314 1.625 3.83 2.29 7.213 1.481 7.555-.808.343-2.78-2.484-4.407-6.313z"
              stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"/>
          <path
              d="M115.406 187.71c-3.249-7.652-4.575-14.41-2.96-15.096 1.614-.685 5.556 4.964 8.805 12.616 3.25 7.653 4.576 14.412 2.961 15.097-1.614.685-5.556-4.963-8.806-12.617z"
              fill="#F5F5F5"/>
          <path
              d="M115.406 187.71c-3.249-7.652-4.575-14.41-2.96-15.096 1.614-.685 5.556 4.964 8.805 12.616 3.25 7.653 4.576 14.412 2.961 15.097-1.614.685-5.556-4.963-8.806-12.617z"
              stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"/>
          <path
              d="M125.79 183.305c-4.355-10.256-6.132-19.314-3.968-20.232 2.163-.918 7.446 6.652 11.8 16.908 4.355 10.255 6.132 19.313 3.969 20.231-2.164.918-7.447-6.652-11.802-16.907z"
              fill-opacity=".35" fill="#F5F5F5"/>
          <path
              d="M125.79 183.305c-4.355-10.256-6.132-19.314-3.968-20.232 2.163-.918 7.446 6.652 11.8 16.908 4.355 10.255 6.132 19.313 3.969 20.231-2.164.918-7.447-6.652-11.802-16.907z"
              stroke="#A3B1BF" stroke-width="1.62" stroke-linejoin="round"/>
          <path
              d="M44.715 90.559c-2.536-1.139-4.219-2.494-3.935-3.273.283-.779 2.439-.736 5.11.02 1.131-2.535 2.462-4.223 3.214-3.95.751.274.686 2.422-.077 5.092 2.532 1.138 4.21 2.49 3.927 3.269-.284.78-2.443.736-5.119-.023-1.12 2.473-2.422 4.106-3.162 3.836-.741-.27-.688-2.357.042-4.971z"
              fill-opacity=".4" fill="#A3B1BF" opacity=".85"/>
          <path
              d="M98.776 330.14l-.351.386a2.43 2.43 0 0 1-4.19-1.201l-.092-.512-.474-.214a2.43 2.43 0 0 1-.152-4.356l.458-.246.057-.518a2.43 2.43 0 0 1 4.095-1.49l.377.36.51-.106a2.43 2.43 0 0 1 2.682 3.434l-.225.469.258.451a2.43 2.43 0 0 1-2.437 3.614l-.516-.07zM412.965 121.001a1.523 1.523 0 0 1-2.291-1.487 1.523 1.523 0 0 1 .707-2.639 1.523 1.523 0 0 1 2.728-.143 1.523 1.523 0 0 1 .98 2.55 1.523 1.523 0 0 1-2.123 1.72zM78.26 39.51l-.833.127a2.43 2.43 0 0 1-2.5-3.57l.405-.738-.378-.752a2.43 2.43 0 0 1 2.623-3.48l.827.156.599-.593a2.43 2.43 0 0 1 4.12 1.419l.107.836.748.386a2.43 2.43 0 0 1-.076 4.357l-.76.36-.136.83a2.43 2.43 0 0 1-4.168 1.275l-.578-.614zM376.631 306.744l-.533-.135a2.43 2.43 0 0 1-.906-4.263l.431-.34-.035-.548a2.43 2.43 0 0 1 3.774-2.179l.456.305.511-.204a2.43 2.43 0 0 1 3.239 2.916l-.15.53.352.422a2.43 2.43 0 0 1-1.773 3.981l-.548.022-.293.464a2.43 2.43 0 0 1-4.334-.455l-.19-.516zM420.263 147.294a1.523 1.523 0 0 1-2.496-1.11 1.523 1.523 0 0 1 .285-2.717 1.523 1.523 0 0 1 2.673-.568 1.523 1.523 0 0 1 1.366 2.365 1.523 1.523 0 0 1-1.828 2.03z"
              fill-opacity=".4" fill="#A3B1BF"/>
          <path
              d="M224.622 221.613c32.537 0 58.914-26.37 58.914-58.902 0-32.53-26.377-58.901-58.914-58.901-32.538 0-58.914 26.37-58.914 58.901 0 32.531 26.376 58.902 58.914 58.902z"
              fill-opacity=".66" fill="#A3B1BF"/>
          <path
              d="M225.346 142.06c-4.2 0-7.465 1.282-9.798 3.847-2.333 2.448-3.44 5.712-3.44 9.792h4.606c0-2.856.642-5.07 1.925-6.703 1.4-1.923 3.557-2.856 6.473-2.856 2.45 0 4.374.641 5.716 2.04 1.283 1.282 1.983 3.09 1.983 5.42 0 1.633-.584 3.148-1.75 4.605-.35.466-1.05 1.166-1.983 2.098-3.15 2.798-5.074 5.013-5.89 6.762-.7 1.457-1.05 3.147-1.05 5.07v1.341h4.666v-1.34c0-1.574.35-2.973 1.108-4.255.583-1.05 1.458-2.099 2.74-3.206 2.567-2.273 4.142-3.789 4.725-4.488 1.458-1.924 2.216-4.197 2.216-6.82 0-3.497-1.108-6.236-3.266-8.218-2.275-2.098-5.249-3.089-8.981-3.089zm-.875 35.496c-.992 0-1.808.291-2.508.99-.7.642-.991 1.458-.991 2.449 0 .99.291 1.807.991 2.506.7.641 1.516.991 2.508.991.991 0 1.808-.35 2.508-.99.7-.642 1.05-1.458 1.05-2.507 0-.99-.35-1.807-.992-2.448-.7-.7-1.575-.99-2.566-.99z"
              fill="#FFF"/>
          <path
              d="M203.262 134.14c5.815-3.357 16.37-7.312 13.012-13.126-3.358-5.815-15.708-4.97-21.524-1.613-5.815 3.357-12.02 13.223-8.662 19.037 3.357 5.815 11.358-.941 17.174-4.298M229.679 120.82a4.863 4.863 0 1 0 .001-9.726 4.863 4.863 0 0 0-.001 9.727z"
              fill="#FAFAFA"/>
          <path d="M401.5 322.5l1.5 1.5" stroke="#979797" stroke-linecap="square"/>
        </g>
      </svg>
    </div>
    <div class="ele-exception-content ele-text-left">
      <h1 class="ele-text">404</h1>
      <p class="ele-text-secondary">抱歉，你访问的页面不存在</p>
      <div>
        <el-button type="primary" @click="$router.push('/')">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404"
}
</script>

<style scoped>
.ele-exception {
  margin: 150px 0;
}

.ele-exception .ele-exception-img svg {
  max-width: 95%;
  width: 320px;
}

.ele-exception .ele-exception-img, .ele-exception .ele-exception-content {
  margin: 15px 30px;
  display: inline-block;
  vertical-align: middle;
}

.ele-exception .ele-exception-content > h1 {
  font-size: 72px;
  font-weight: 600;
  margin: 0 0 20px 0;
}

.ele-exception:not(.ele-exception-dark) .ele-exception-content > h1 {
  color: #515A6E;
}

.ele-exception .ele-exception-content > p {
  font-size: 20px;
  margin: 0 0 25px 0;
}

.ele-exception:not(.ele-exception-dark) .ele-exception-content > p {
  color: #808695;
}

/** 适配暗黑主题 */
.ele-exception-dark .ele-exception-img {
  opacity: .7;
}

/** 小屏幕样式 */
@media screen and (max-width: 768px) {
  .ele-exception {
    margin: 40px 0;
  }

  .ele-exception .ele-exception-img {
    margin: 0;
  }

  .ele-exception .ele-exception-img svg {
    max-height: 200px;
  }

  .ele-exception .ele-exception-content {
    text-align: center;
  }
}
</style>